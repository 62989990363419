<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
	name: 'App',
	data() {
		return {};
	},
	metaInfo() {
		return {
			meta: [
				{
					name: 'viewport',
					content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no'
				}
			]
		};
	}
};
</script>
<style lang="stylus">
#app, body, html
	font-family "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif
	padding 0
	margin 0
	width 100%
	min-height 100%
	word-break break-all
	user-select none
ul
	list-style-type none
	margin 0
	padding 0
button
	outline none
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import axios from "axios";
import MetaInfo from "vue-meta-info";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
// test
// Vue.prototype.appId = "wx5cfd817341bdc0c9";
// prod
Vue.prototype.appId = "wx9b2ae7cf15dfbb2e";
import "./assets/style.css";
Vue.use(Vant);
Vue.use(MetaInfo);

axios.interceptors.request.use((config) => {
  if (["home"].indexOf(config.url) === -1) {
    const token = localStorage.getItem("z-token");
    if (token) {
      config.headers.token = `${token}`;
    }
    return config;
  }
});

// axios响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是2开头的的情况
  // 根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  // 下面列举几个常见的操作，其他需求可自行扩展
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          Vue.prototype.$message({
            message: "身份验证失败，请重新登录",
            type: "error",
          });
          // window.location.href = "/";
          router.push("/");
          break;
        // break;
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          Vue.prototype.$message({
            message: "登录过期，请重新登录",
            type: "error",
          });
          // 清除token
          break;
        // 404请求不存在
        case 404:
          Vue.prototype.$message({
            message: "您访问的网页不存在",
            type: "error",
          });
          break;
        // 其他错误，直接抛出错误提示
        default:
          Vue.prototype.$message({
            message: error.response.data.message,
            type: "error",
          });
      }
      return Promise.reject(error.response);
    }
  }
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";
import VueRouter from "vue-router";
import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/login/Login.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/Login.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/info",
    name: "UserInfo",
    component: () => import("../views/user/UserInfo.vue"),
  },
  {
    path: "/center",
    name: "UserCenter",
    component: () => import("../views/user/UserCenter.vue"),
  },
  {
    path: "/task/list",
    name: "TaskList",
    component: () => import("../views/task/TaskList.vue"),
  },
  {
    path: "/task/record",
    name: "TaskRecord",
    component: () => import("../views/task/TaskRecord.vue"),
  },
  {
    path: "/task/detail",
    name: "TaskDetail",
    component: () => import("../views/task/TaskDetail.vue"),
    props: (route) => ({ id: route.query.id }),
  },
  {
    path: "/task/success",
    name: "TaskAcceptSuccess",
    component: () => import("../views/task/TaskAcceptSuccess.vue"),
    props: (route) => ({ id: route.query.id }),
  },
  {
    path: "/task/complete",
    name: "TaskComplete",
    component: () => import("../views/task/TaskComplete.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
